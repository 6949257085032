body {
  background-color: #F1F5FA;
}

.download-btn {
  height: fit-content;
  position: absolute;
  z-index: 2;
  top: 3%;
  right: 4%;
}

.download-check {
  height: fit-content;
  position: absolute;
  z-index: 2;
  top: 3%;
  left: 5%;
}

.btn-in {
  opacity: 0;
}

.not-selected:hover~.btn-in {
  opacity: 1;
}

.download-back {
  background: white;
  opacity: 0.8;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.header {
  overflow: hidden;
  background-color: white;
  padding: 20px 10px;
}

.logo {
  width: 70px;
  height: 70px;
}

.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 10px 25px;
  text-decoration: none;
  font-size: 16px;
  line-height: 25px;
  border-radius: 8px;
}

.header .logo {
  font-size: 20px;
  font-weight: bold;
}

.header a:hover {
  background-color: #ddd;
  color: black;
}

.header a.active {
  background-color: #FF3333;
  color: white;
}

.header a.select {
  background-color: #F1F5FA;
  color: black;
}

.header-right {
  float: right;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }

  .header-right {
    float: none;
  }
}

.main-div {
  background-color: white;
  border-radius: 10px;
}

.fidownload {
  color: #FF3333;
}


.label-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.label-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.label-checkbox input:checked~.checkmark {
  background-color: #FF3333;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label-checkbox .checkmark:after {
  left: 9px;
  top: 4px;
  width: 7px;
  height: 14px;
  border: 0.5px solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Loader css */
.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0000008c;
  left: 0;
  top: 0;
  z-index: 9999;
}

.is-active {
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;

}

.loadingtxt {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.loadingImg {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}